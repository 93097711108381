.tableBox{
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    &_close{
        height: 0px;
        transition: all 1s ease;
        overflow: hidden;
    }
    &_open{
        transition: all 1s ease;
        height: auto;    
    }
}
.menuTableBox{
    &_active{
        background: #fafafa;
        border: 1px solid #f6f6f6;
        border-radius: 10px 10px 0px 0px;
        border-bottom: '1px solid #fff';
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    }
    &_stock{
        background: #fff;
        border: 1px solid #faf8f8;
        border-radius: 10px 10px 0px 0px;
        border-bottom: '1px solid #fff';
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    }
}