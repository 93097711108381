.orderPage{
    width: 90vw;
    height: 80vh;
    max-width: 1400px;
    max-height: 1200px;
    &_open{
        background-color: #fff;
        border-radius: 10px;
        position: absolute;
        z-index: 999;
        transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
        top: 20px;
    }
    &_closed{
        transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
        top: 100%;
        position: fixed;
        left: 0;
        text-align: left;
    }
}

.productBox{
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border: 1px solid #fff;
    &_grey{
        border: 1px dashed #dcdcdc;
    }
    &_active{
        border: 1px solid #1890ff;
        background-color: #e4f2ff;
        position: relative;
    }
}